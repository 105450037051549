export async function getTestSeriesData(params) {
    try {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };
        console.log("getTestSeriesData-> ", requestOptions)
        const response = await fetch('/api/post/getFilteredFRTests', requestOptions)
        const data = await response.json();
        
        return data;
    } catch (error) {

        console.error('Error fetching data from API:', error);
        throw error;
    }
}

export async function getBalanceComparison(params = {}) {
    try {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };
        
        const response = await fetch('/api/post/balanceReadings/getBalanceComparison', requestOptions)
        const data = await response.json();
        
        return data;
    } catch (error) {

        console.error('Error fetching data from API:', error);
        throw error;
    }
}


export async function getTestDataRange(params = {}) {
    try {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };
        
        const response = await fetch('/api/post/testRun/getTestDataRange', requestOptions)
        const data = await response.json();
        
        return data;
    } catch (error) {

        console.error('Error fetching data from API:', error);
        throw error;
    }
}


export async function getUniqueColumnVals(params) {
    try {
        const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'text/plain' },
        body: JSON.stringify(params)
        };

        const response = await fetch('/api/post/getFilterValueOptions', requestOptions)
        const data = await response.json();
        return data;
        
    } catch (error) {

        console.error('Error fetching data from API:', error);
        throw error;
    }
}


export async function getTestSeriesList() {
    try {
        const requestOptions = {
        method: 'GET',
        };

        const response = await fetch('/api/get/getTestSeriesList', requestOptions)
        const data = await response.json();
        return data;
        
    } catch (error) {

        console.error('Error fetching data from API:', error);
        throw error;
    }
}

export async function fetchUserSettings(ms_account) {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ms_account}),
      };
      
      const response = await fetch('/api/post/handleWebUser', requestOptions);
      const data = await response.json();
      console.log("got new user settings: ", data)
      return(data);
    } catch (error) {
      console.error('Error fetching user settings:', error);
    }
};



export async function getTestRunTimeSeries(params) {
    try {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };
        
        const response = await fetch('/api/post/testRun/getTestTimeSeries', requestOptions)
        const data = await response.json();
        
        return data;
    } catch (error) {

        console.error('Error fetching data from API:', error);
        throw error;
    }
}


export async function getTestPreviewGraphData(params) {
    try {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };
        
        const response = await fetch('/api/post/graphData/getTestPreviewData', requestOptions)
        const data = await response.json();
        
        return data;
    } catch (error) {

        console.error('Error fetching data from API:', error);
        throw error;
    }
}


export async function postTestRunUpdateMarkup(params) {
    try {
        console.log("Got request to send testrun markup: ", params)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };
        
        const response = await fetch('/api/post/testRun/updateTestRunMarkup', requestOptions)
        const data = await response.json();
        
        return data;
    } catch (error) {

        console.error('Error Posting data to API:', error);
        throw error;
    }
}


export async function updateUserGraphSettings(params) {
    try {
        console.log("Got request to update user graph settings: ", params)

        const updateRequest = {userId: params.userId, reportType: "memorizedGraph",
            reportName: "memorizedGraph",
            reportDescription: "Allow users last used graph settings to persist between sessions.",
            reportOptions: params.graphSettings}
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(updateRequest)
        };
        
        const response = await fetch('/api/post/createReportOption', requestOptions)
        const data = await response.json();
        
        return data;
    } catch (error) {

        console.error('Error Posting data to API:', error);
        throw error;
    }
}

export async function getSimilarTests() {
    try {

        const requestOptions = {
            method: 'GET',
        };

        const response = await fetch('/api/get/getSimilarTests', requestOptions)
        const data = await response.json();

        return data;
    } catch (error) {

        console.error('Error fetching data from API:', error);
        throw error;
    }
}

export async function getLastCalibration(params = {}) {
    // This function breaks things and is disabled. 
    return {}
    /*try {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(params)
        };

        const response = await fetch('/api/post/testKit/getLastCalibration', requestOptions)
        const data = await response.json();

        return data;
    } catch (error) {

        console.error('Error fetching data from API:', error);
        throw error;
    }
        */
}